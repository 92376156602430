import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { OTSubscriber, OTPublisher } from 'opentok-react';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Unmute from './Unmute';
import Raise from './Raise';
import Mute from './Mute';
import { STUDENTS_LAYOUT } from "../utility/config"
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { deepOrange } from '@mui/material/colors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "./students.css";
import Student from './Student';
import Speaker from './Speaker';

const styles = theme => ({
  container: {
    main:"main"
  },
  spacious: {   // class name
    padding: 10
  },
});
const classes = styles();

export default function Students({ 
  layout,
  sessionHelper, 
  streams, 
  teacher, 
  currentParticipant,
  audio,
  video,
  setAudio,
  videoSource,
  publisherEventHandlers
}) {
  const [activeStudent, setActiveStudent] = useState(null);
  const handleClick = (event, id) => {
    if (event.detail === 2) {
      makeBigger(id)
    }
  };
  const makeBigger = (id) => {
      setActiveStudent(activeStudent ===  id ? null : id )
  };

  const studentName = currentParticipant.userName.slice(0,12);

  return (
    <ImageList 
      cols={layout === STUDENTS_LAYOUT.GRID ? 4 : 1} 
      rowHeight={activeStudent===null && layout === STUDENTS_LAYOUT.GRID ? 250 : 80} 
      sx={activeStudent===null ? {
        flexGrow: 1,
        height: '100px',
        marginTop: 0,
        gridTemplateRows: 'max-content',
        gridAutoRows: 'max-content'
      } : {
        flexGrow: 1,
        height: '100%',
        marginTop: 0,
        marginBotton:20
      }}
      className={classes.container}
    >
      {!currentParticipant?.isOwner && (
        <ImageListItem 
          key={currentParticipant.userId}
          onClick={(event) => handleClick(event, currentParticipant.userId)} 
          sx={{height: activeStudent===currentParticipant.userId ? '100% !important' : (`${layout === STUDENTS_LAYOUT.GRID ? 250 : 80}px !important`)}} 
          cols={activeStudent===currentParticipant.userId ? 4 : 1}
          className={activeStudent===null ? 'activeStudent' : (activeStudent === currentParticipant.userId ? 'activeStudent': 'hideStudent')}
        >
          <div style={{ width: '100%', height: (layout === STUDENTS_LAYOUT.GRID ? '100%': 0) , visibility: (layout === STUDENTS_LAYOUT.GRID ? 'visible' : 'hidden') }}>
            {activeStudent === currentParticipant.userId && <button className='backBtn' onClick={()=>makeBigger(currentParticipant.userId)}><ArrowBackIcon /></button>}
            <OTPublisher 
                properties={{
                  showControls: false,
                  publishAudio: audio,
                  publishVideo: video,
                  videoSource: videoSource === 'screen' ? 'screen' : undefined
                }} 
                eventHandlers={publisherEventHandlers}
                style={{height: '83%'}}
                className="opshadow"
                session={sessionHelper.session}
              />
            <ImageListItemBar
                // sx={{ background: "", color: "#ffffff", opacity:"0.5", }}
                // title={<div style={{ color: "black" }}>{currentParticipant.userName}</div>}
                title={
                  <div style={{textAlign:"left", paddingLeft:'8px', alignItems:'left'}} title={currentParticipant.userName}>{
                      studentName === currentParticipant.userName ? 
                      studentName : studentName + '...'
                    }
                  </div>
                }
                onMouseEnter={() => {
                <div title={currentParticipant.userName}>
                </div>
                }}
                // subtitle={item.author}
                position="below"
                variant="standard"
                actionIcon={
                  <IconButton 
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    aria-label={`info about student`}
                    onClick={() => setAudio(!audio)}
                  >
                    {/* <Unmute color={audio?"#088ef4":"red"}/> */}
                  </IconButton>
                }
              />
          </div>
          {layout === STUDENTS_LAYOUT.LIST && (
            <div style={{ width: '100%', height:'100% !important', background: 'black', spacing:"1" }}>
              <Stack direction="row" rowHeight={5} spacing={1} alignItems="center">
                <Avatar sx={{ bgcolor: deepOrange[500], marginLeft: "15px" }} variant="square">
                {currentParticipant.userName.slice(0,1)}
                </Avatar>
                <p style={{ color: "white",left:"6px",flex:1 }}>{currentParticipant.userName}</p>
                <IconButton sx={{ height: "70px", left: "10%", width: "31px" }}><Unmute /></IconButton>
                {/* <IconButton sx={{ height: "70px", left: "1%" }}><Raise /></IconButton> */}
                {/* <IconButton sx={{ height: "70px", left: "25%",width:"35px" }}><CameraOn /></IconButton> */}
                {/* {activeStudent=== currentParticipant.userId ?(<button style={{background: 'black',color:"white", alignItems:"center"}}><ArrowBackIcon /></button>): null} */}
              </Stack>
            </div>
          )}
        </ImageListItem>
      )}
      {streams
        .filter(obj => {
          return JSON.parse(obj.connection?.data).userId !== teacher?.userId
        })
        .map(stream => (
            <ImageListItem
            key={stream.id}
            sx={{height: activeStudent===stream.id ? '100% !important' : (`${layout === STUDENTS_LAYOUT.GRID ? 250 : 80}px !important`)}} 
            cols={activeStudent===stream.id ? 4 : 1} 
            onClick={(event) => handleClick(event, stream.id)} 
            className={activeStudent===null ? 'activeStudent' : (activeStudent === stream.id ? 'activeStudent': 'hideStudent')}
          >
            {layout === STUDENTS_LAYOUT.GRID && (
              <Student 
                activeStudent={activeStudent}
                stream={stream}
                makeBigger={makeBigger}
                sessionHelper={sessionHelper}
              />
            )}
            {layout === STUDENTS_LAYOUT.LIST && (
              <div style={{ width: '100%', height: '20px !important', background: 'black' }}>
              <Stack direction="row"  spacing={1} alignItems="center">
                  <Avatar sx={{ bgcolor: deepOrange[500], marginLeft: "15px" }} variant="square">
                  {JSON.parse(stream.connection.data).userName.slice(0,1)}
                  </Avatar>
                  <p style={{ color: "white",left:"6px", flex: 1 }}>{JSON.parse(stream.connection.data).userName}</p>
                  <IconButton sx={{ height: "70px", left: "10%", width: "31px" }}><Unmute /></IconButton>
                  {/* <IconButton sx={{ height: "70px", left: "1%" }}><Raise /></IconButton> */}
                  {/* <IconButton sx={{ height: "70px", left: "25%",width:"35px" }}><CameraOn /></IconButton> */}
                  {activeStudent=== stream.id ?(<button style={{background: 'black',color:"white", alignItems:"center"}}><ArrowBackIcon /></button>): null};
                </Stack>
              </div>
            )}
          </ImageListItem>
        ))
      }
    </ImageList>
  );
}