import PropTypes from "prop-types";

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import DominionLogo from "./DominionLogo";

const NavBar = (props) => {
  return (
    <AppBar color="secondary" sx={{ padding:"5px", position: "static" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <DominionLogo/>
          <Box>
            <Typography
              variant="h4"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'Roboto',
                fontWeight: 700,
                lineHeight: 1,
                transform: 'scaleY(.85)',
                color: 'inherit',
                textDecoration: 'none',
                letterSpacing: '0.3rem',
                fontSize: "x-large"
              }}
            >
              DOMINION PREP
            </Typography>
            <Typography variant="subtitle" sx={{
                fontSize: "small"
              }}>Accelerated Results. Extraordinary Opportunities.</Typography>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

NavBar.propTypes = {
  copyright: PropTypes.bool,
};
export default NavBar;