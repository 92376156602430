import { v4 as uuidv4 } from 'uuid';

export default class Shape {
    constructor(type, x=null, y=null, points, strokeColor='', fillColor='', strokeSize='') {
        this.id= uuidv4();
        this.x =x;
        this.y = y;
        this.type = type;
        this.points = points;
        this.strokeColor = strokeColor;
        this.fillColor = fillColor;
        this.strokeSize = strokeSize;

      }

}