import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
// import ToggleButton from '@mui/material/ToggleButton';
// import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// import WhiteBoard from '../Whiteboard';
import { useNavigate } from 'react-router-dom';
import { createSession, OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react';
import Teacher from '../Components/Teacher';
import Students from '../Components/Students';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { STUDENTS_LAYOUT } from '../utility/config'

import ActionBar from '../Components/ActionBar';

import styles from './conversation.module.css';
import Student from '../Components/Student';

// const apiKey = '47611701';
const apiKey = process.env.REACT_APP_OPENTOK_API_KEY;
const OT = require("@opentok/client");

function ConversationPage() {
  
  const { conversationId, userId } = useParams();
  const [layout, setLayout] = useState(STUDENTS_LAYOUT.GRID);
  const [displaySidebar, setDisplaySidebar] = useState(true);
  const [conversation, setConversation] = useState({
    participants: [],
  });

  const [participants, setParticipants] = useState([]);
  const [currentParticipant, setCurrentParticipant] = useState({});
  const [teacher, setTeacher] = useState({});

  const [otPublisher, setOtPublisher] = useState();
  const [otSubscribers, setOtSubscribers] = useState([]);
  const [otSession, setOtSession] = useState(null);

  const [screenShare, setScreenShare] = useState(false);
  const [whiteBoard, setWhiteBoard] = useState(false);

  let navigate = useNavigate();

  // On Component Load
  useEffect(() => {
    initSession();
    return () => {
      doLeave()
      //   setOtSession(null);
      //   setOtPublisher(null);
      //   setOtSubscribers([]);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    
    if (conversation?.opentokSessionId && currentParticipant?.opentokToken){
      const otSession = createSession({
        apiKey,
        sessionId: conversation.opentokSessionId,
        token: currentParticipant.opentokToken,
        onStreamsUpdated: streams => {
          setOtSubscribers(streams)
        }
      });
      console.log(222, otSession)
      setOtSession(otSession);
    }
  },[conversation, currentParticipant])
  
  async function initSession() {
    try {
      const payload = await axios.post(
        process.env.REACT_APP_API_BASE_URL + `/conversation/${conversationId}/join`, 
        { userId: userId, userName: userId }
      );
      setConversation(payload.data);
      prepareParticipants(payload.data.participants, userId);
    } catch (err) {
      goToHomeScreen();
    }
  }

  function prepareParticipants(participants, userId) {
    const current = participants.filter(participant => participant.userId === userId);
    const others = participants.filter(participant => participant.isOwner !== true && participant.userId !== userId);
    const owner = participants.filter(participant => participant.isOwner);
    setTeacher(owner[0]);
    setCurrentParticipant(current[0]);
    // separating teacher and participants
    setParticipants(current[0].isOwner ? others : [...current, ...others]);
  }

  useEffect(() => {
    
    
    // initSessionCallback();
  }, [otSession]);

  useEffect(() => {
    console.log("INIT OPENTOK");
    // initOpentok();
    // return() => {
    //     // setOtSession(null);
    //     setParticipants([]);
    //     setCurrentParticipant({});
    //     console.log('DESTROY INIT OPENTOK')
    // }
  }, [currentParticipant]);

  useEffect(() => {
    console.log("EFFECT TOGGLE SCREENSHARE");
    // toggleScreenShare();
  }, [screenShare]);

  function goToHomeScreen() {
    navigate('/');
  }
  //   useEffect(() => {
  //     console.log("INIT OPENTOK");
  //     if(!whiteBoard) {
  //         initOpentok();
  //     }
  //   }, [whiteBoard]);

  function changeStudentsLayout() {
    if (layout === STUDENTS_LAYOUT.GRID) {
      setLayout(STUDENTS_LAYOUT.LIST);
    } else {
      setLayout(STUDENTS_LAYOUT.GRID);
    }
  }

  function changeStudentsGridLayout() {
    setDisplaySidebar (!displaySidebar)
  }

  function fetchParticipantByToken(streamData) {
    // console.log(participants);
    let parti = participants.filter((participant) => {
      //   console.log(streamData.userName);
      //   console.log(participant.userName);
      if (participant.userName === streamData.userName) {
        return participant;
      }
    });

    return parti[0];
  }

  // function toggleScreenShare() {
  //   // console.log(currentParticipant);
  //   // console.log(screenShare);
  //   // console.log(otSession);

  //   let vidSource = true;
  //   if (screenShare) {
  //     //otPublisher.unSubscribe();
  //     vidSource = "screen";
  //   }
  //   // console.log(vidSource);

  //   if (otPublisher) {
  //     //   console.log(otPublisher);
  //     otPublisher.destroy();
  //     //otSession.unsubscribe(otPublisher);
  //     // let pubs = await otPublisher.setVideoSource(vidSource);
  //   }

  //   // console.log(currentParticipant._id);
  //   if (currentParticipant._id) {
  //     let publisher = OT.initPublisher(
  //       currentParticipant._id,
  //       {
  //         insertMode: "append",
  //         width: "100%",
  //         height: "100%",
  //         name: currentParticipant.userName,
  //         videoSource: vidSource,
  //       },
  //       handleError
  //     );

  //     setOtPublisher(publisher);
  //     if (otSession) {
  //       const puber = otSession.publish(publisher, handleError);
  //       // console.log(puber);
  //     }
  //   }
  // }

  function hasSubscriber(otsubs, name) {
    console.log(otsubs.length);
    console.log(otsubs);
    for (let subscriber of otsubs) {
      console.log("==================");

      console.log(name);
      console.log(subscriber);
      console.log(subscriber.name === name);

      console.log("/==================");

      if (subscriber.name === name) {
        return true;
      } else {
        return false;
      }
    }

    return null;
  }

  // function removeSubscriber(name) {
  //   console.log(otSubscribers);
  //   // setOtSubscribers(ots);
  //   setOtSubscribers((otSubscribers) => {
  //     console.log(otSubscribers);
  //     _.remove(otSubscribers, function (subs) {
  //       console.log(subs);
  //       console.log(name);
  //       console.log(subs.name === name);
  //       return subs.name === name;
  //     });

  //     return otSubscribers;
  //   });

  //   console.log(otSubscribers);
  // }

  // function initSessionCallback() {
  //   console.log("INIT SESSION CALLBACK");
  //   if (currentParticipant._id) {
  //     console.log("OT currentParticipant");

  //     let publisher = OT.initPublisher(
  //       currentParticipant._id,
  //       {
  //         insertMode: "append",
  //         width: "100%",
  //         height: "100%",
  //         name: currentParticipant.userName,
  //         //videoSource: vidSource,
  //       },
  //       handleError
  //     );

  //     publisher.on("streamDestroyed", function (event) {
  //       //event.preventDefault();
  //       console.log("The publisher stopped streaming.");
  //     });

  //     setOtPublisher(publisher);

  //     if (otSession) {
  //       console.log("OT SESSION");

  //       //Publisher screen
  //       otSession.connect(currentParticipant.opentokToken, function (error) {
  //         // If the connection is successful, publish to the session
  //         // console.log(otPublisher);
  //         if (error) {
  //           handleError(error);
  //         } else {
  //           const puber = otSession.publish(publisher, handleError);
  //           console.log(puber);
  //         }
  //       });

  //       otSession.on("streamCreated", function (event) {
  //         console.log("STREAM created");
  //         console.log(event);
  //         let streamData = JSON.parse(event.stream.connection.data);
  //         let partStream = fetchParticipantByToken(streamData);
  //         console.log(partStream);
  //         console.log(otSubscribers);
  //         console.log(streamData);
  //         const hasSubs = hasSubscriber(otSubscribers, streamData.userName);
  //         console.log(hasSubs);

  //         /*
  //          * If current publisher is same the don't subscribe
  //          */
  //         if (!hasSubs) {
  //           console.warn("NEW SUBSCRIBE");
  //           const otSubscriber = otSession.subscribe(
  //             event.stream,
  //             partStream._id,
  //             {
  //               insertMode: "append",
  //               width: "100%",
  //               height: "100%",
  //             },
  //             handleError
  //           );

  //           otSubscribers.push({ name: streamData.userName });
  //           setOtSubscribers(otSubscribers);
  //         } else {
  //           console.warn("Stream is already subscribed for this subscriber [CUSTOM]");
  //         }
  //       });

  //       otSession.on("streamPropertyChanged", function (event) {
  //         console.log("STREAM prop changed");
  //       });

  //       otSession.on("streamDestroyed", function (event) {
  //         console.log(event);
  //         console.log("STREAM destroyed");
  //         removeSubscriber(event.stream.name);
  //       });
  //     }
  //   }
  // }

  function handleError(err) {
    console.error(err);
  }

  function handleScreenShare(event, value) {
    // console.log('FOO');
    console.log(value);
    setScreenShare(value);
  }

  function handleWhiteboard(event, value) {
    // console.log('FOO');
    console.log(value);
    setWhiteBoard(value);
  }

  function doLeave(ev) {
    // otSession.disconnect();
    // setOtSession(null);
    // console.log(otSubscribers);
    // for (let otSubscriber of otSubscribers) {
    //   console.log(otSubscriber);
    //   otSession.unsubscribe(otSubscriber);
    // }
    goToHomeScreen();
  }

  return (
    <>
        { conversation?.opentokSessionId && currentParticipant?.opentokToken && otSession?.session &&(
            <ImageList cols={7} className={styles.mainArea}>
              <ImageListItem cols={displaySidebar ? (layout === STUDENTS_LAYOUT.GRID ? 3 : 5) : 7} sx={{ height: '100% !important' }}>
                <Teacher 
                  sessionHelper={otSession} 
                  streams={otSubscribers}
                  teacher={teacher} 
                  currentParticipant={currentParticipant}
                />
              </ImageListItem>
              { displaySidebar && (
                <ImageListItem  cols={layout === STUDENTS_LAYOUT.GRID ? 4 : 2 } sx={{ height: '100% !important' }}>
                  <Students 
                    sessionHelper={otSession} 
                    streams={otSubscribers}
                    teacher={teacher} 
                    currentParticipant={currentParticipant}
                    layout={layout} 
                    displaySidebar={displaySidebar} 
                    sample={true}
                  />
                </ImageListItem>
              )}
            </ImageList>
        ) }
        
        <ActionBar 
          doLeave={doLeave}
          displaySidebar={displaySidebar} 
          changeStudentsGridLayout={changeStudentsGridLayout} 
          changeStudentsLayout={changeStudentsLayout}
        />
    </>
  );
}

export default ConversationPage;
