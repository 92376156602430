import Box from "@mui/material/Box";

function Home() {
  return (
    <Box sx={{ 
      flexGrow: 1, 
      overflow: 'auto', 
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <h2 style={{ marginBottom: 0}}>Welcome to</h2>
      <h1>Dominion Prep</h1>
    </Box>
  );
}

export default Home;