const Hamburger = ({color}) =>{
    return (<svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 1.5C0 0.671573 0.671573 0 1.5 0H18.5C19.3284 0 20 0.671573 20 1.5C20 2.32843 19.3284 3 18.5 3H1.5C0.671573 3 0 2.32843 0 1.5Z" fill={color}/>
    <path d="M0 7.5C0 6.67157 0.671573 6 1.5 6H18.5C19.3284 6 20 6.67157 20 7.5C20 8.32843 19.3284 9 18.5 9H1.5C0.671573 9 0 8.32843 0 7.5Z" fill={color}/>
    <path d="M0 13.5C0 12.6716 0.671573 12 1.5 12H18.5C19.3284 12 20 12.6716 20 13.5C20 14.3284 19.3284 15 18.5 15H1.5C0.671573 15 0 14.3284 0 13.5Z" fill={color}/>
    </svg>)
    
    
}

export default Hamburger ;