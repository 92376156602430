import { Delete, Mode, Pages, PanToolAlt } from "@mui/icons-material";
import { Divider, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { Stage, Layer, Line, Text, Rect, Circle } from "react-konva";
import { io } from "socket.io-client";
import Shape from "./Shape";

const WhiteBoard = () => {
  const [sock, setSock] = useState(null);
  const [tool, setTool] = React.useState("pen");
  // const [lines, setLines] = React.useState([]);
  const [shapes, setShapes] = React.useState([]);
  const isDrawing = React.useRef(false);

  /**
   * Handle socket message
   */
  function handleSocket() {
    console.log("socket conenct");
    socket = io(process.env.REACT_APP_SOCKETIO_PORT);
    setSock(socket);
    // client-side
    socket.on("connect", () => {
      console.log(socket.id); // x8WIv7-mJelg7on_ALbx
    });

    socket.on("disconnect", () => {
      console.log(socket.id); // undefined
    });

    socket.on("onDraw", (shape) => {
      // console.log(shapes);
      console.log("drawing sync"); // undefined
      console.log(shape);

      setShapes((oldShapes) => [...oldShapes, shape]);
    });

    socket.on("onErase", (shape) => {
      setShapes((oldShapes) => {
        const [targetShape, modShapes] = findShape(oldShapes, shape.id);
        return modShapes;
      });

      console.log(shapes);
      console.log("erase sync"); // undefined
      console.log(shape);
    });


    socket.on("onDrag", (shape) => {
      setShapes((oldShapes) => {
        let modShapes = oldShapes.map((elem) => {
          if(elem.id === shape.id) {
            console.log('update drag')
            console.log(shape)
            elem = shape;
          }

          return elem;
        });

        return modShapes;
      });

      console.log(shapes);
      console.log("drag sync"); // undefined
      console.log(shape);
    });
  }

  /**
   * Socket io
   */
  var socket;
  useEffect(() => {
    handleSocket();
  }, []);

  const handleMouseDown = (e) => {
    console.log("MOUSE DOWN");
    console.log(e.target);


    // console.log(tool);
    if (tool === "pen") {
      isDrawing.current = true;
      const pos = e.target.getStage().getPointerPosition();
      const posX = null;
      const posY = null;
      let shape = new Shape(tool, posX, posY, [pos.x, pos.y], "black");
      setShapes([...shapes, shape]);
    } else {
      isDrawing.current = false;
    }
  };

  const handleMouseMove = (e) => {
    // no drawing - skipping

    if (!isDrawing.current) {
      return;
    }
    console.log("MOUSE DRAG");

    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    console.log( e.target);

    /**
     * @todo filter by tool line
     */
    let lastShapes = shapes[shapes.length - 1];
    console.log(lastShapes);
    // add point
    lastShapes.points = lastShapes.points.concat([point.x, point.y]);

    // replace last
    shapes.splice(shapes.length - 1, 1, lastShapes);
    setShapes(shapes.concat());
  };

  const handleMouseUp = () => {
    console.log("MOUSE UP");
    // console.log(sock);
    // console.log(shapes);

    isDrawing.current = false;

    if (tool === "pen") {
      let shape = shapes[shapes.length - 1];
      console.log(shape);
      sock.emit("draw", shape);
    } else {
      console.error("No valid tools!");
    }
  };

  // function addShape(ev, shape, color, stroke) {
  //   // console.log(ev);
  //   activateTool(ev, shape);
  //   setShapes((oldShapes) => [
  //     ...oldShapes,
  //     {
  //       shape: shape,
  //       color: color,
  //       stroke: stroke,
  //     },
  //   ]);
  // }

  function handleSelect(e) {
    console.log("Shape selected");
    // console.log(e.target.id());
    if (tool === "eraser") {
      console.log("eraser select");
      const targetId = e.target.id();
      const [targetShape, modShapes] = findShape(shapes, targetId);
      setShapes(modShapes);
      sock.emit("erase", targetShape);
    }
  }

  function handleDragEnd(e) {
    if (tool === "select") {
      console.log("DRAG END");
      const pos = e.target.getStage().getPointerPosition();
      console.log(pos);

      const targetId = e.target.id();
      const [targetShape, modShapes] = findShape(shapes, targetId);

      targetShape.x=e.target.getX();
      targetShape.y=e.target.getY();
    
      console.log(e.target);
      sock.emit("drag", targetShape);
    }
  }

  function findShape(shapes, targetId) {
    // console.log(shapes);
    const modShapes = shapes.filter((shape) => {
      // console.log(shape.id, targetId);
      return shape.id != targetId;
    });
    const targetShape = shapes.filter((shape) => {
      return shape.id === targetId;
    });

    return [targetShape[0], modShapes];
  }

  /**
   * Render multiple shapes and line
   * @param {*} i
   * @param {*} shape
   * @returns
   */
  function renderShape(shape) {
    let comp;
    if(!shape) {
      console.error('No shape');
      return null;
    }

    if (shape.type === "pen") {
      comp = (
        <Line
          id={shape.id}
          onMouseDown={handleSelect}
          onDragEnd={handleDragEnd}
          draggable
          key={shape.id}
          points={shape.points}
          x={shape.x}
          y={shape.y}
          stroke="#df4b26"
          strokeWidth={5}
          tension={0.5}
          lineCap="round"
          globalCompositeOperation="source-over"
        />
      );
      // } else if (shape.type === "eraser") {
      //    comp = (
      //     <Line
      //       key={shape.id}
      //       draggable
      //       points={shape.points}
      //       stroke="#df4b26"
      //       strokeWidth={5}
      //       tension={0.5}
      //       lineCap="round"
      //       globalCompositeOperation="destination-out"
      //     />
      //   );
    } else if (shape.type === "rect") {
      comp = (
        <Rect
          key={shape.id}
          draggable
          x={20}
          y={50}
          width={100}
          height={100}
          fill="red"
          shadowBlur={10}
        />
      );
    } else if (shape.type === "circle") {
      comp = (
        <Circle
          x={200}
          y={100}
          radius={50}
          fill="green"
          key={shape.id}
          draggable
        />
      );
    }

    // return <Layer draggable>{comp}</Layer>;

    return comp;
  }

  const handleTool = (event, tool) => {
    setTool(tool);
  };

  return (
    <div>
      <ToggleButtonGroup
        value={tool}
        exclusive
        onChange={handleTool}
        aria-label="text formatting"
      >
        <ToggleButton value="select" aria-label="select">
          <PanToolAlt />
        </ToggleButton>
        <ToggleButton value="pen" aria-label="pen">
          <Mode />
        </ToggleButton>
        <ToggleButton value="eraser" aria-label="eraser">
          <Delete />
        </ToggleButton>
        <ToggleButton value="text" aria-label="color" disabled>
          <Pages />
        </ToggleButton>
      </ToggleButtonGroup>

      <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />

      <h6>Tools: {tool}</h6>
      <Stage
        width={window.innerWidth}
        height={window.innerHeight}
        onMouseDown={handleMouseDown}
        onMousemove={handleMouseMove}
        onMouseup={handleMouseUp}
      >
        <Layer>
          <Text text="Just start drawing" x={5} y={30} />
          {shapes.map((shape, i) => renderShape(shape))}
        </Layer>
      </Stage>
    </div>
  );
};

export default WhiteBoard;
