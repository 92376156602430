import { useEffect, useState } from "react";
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Stack from '@mui/material/Stack';
import { BottomNavigation, Box, Button, Grid, Item } from "@mui/material";
import Unmute from './Unmute';
import Mute from './Mute';
import Sharing from "./Sharing";
import CameraOn from "./CameraOn";
import Hamburger from "./Hamburger";
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import zIndex from "@mui/material/styles/zIndex";
import { STUDENTS_LAYOUT } from "../utility/config";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Fullscreen, FullscreenExit, FullscreenOutlined, FullscreenSharp } from "@mui/icons-material";
import FullScreenSvg from "./FullScreenSvg";

const actionBarStyle = {
  position: 'absolute',
  left: 0,
  bottom: 0,
  width: '100%',
  height: '80px',
  background: '#231F20',
  zIndex:"6"
};

const ActionBar = ({
  teacher,
  changeStudentsGridLayout, 
  changeStudentsLayout, 
  displaySidebar, 
  doLeave,
  setAudio,
  setVideo,
  audio,
  video,
  layout,
  setVideoSource,
  videoSource,
  currentParticipant
}) => {

  return <ImageListItemBar
  sx={actionBarStyle}
  title={
    <div>
      <Typography variant="title">{teacher.userName}</Typography>
      {/* <Typography variant="poster">Human Geography & World Culture</Typography>
      <Typography variant="subtitle">Class Code - 5A</Typography> */}
    </div>
  }
  actionIcon={
    <Stack spacing={1} direction="row" sx={{paddingRight: '1rem'}}>
      <Button onClick={() => setAudio(!audio)}><Unmute color={audio?"#088ef4":"red"}/></Button>
      
      {currentParticipant?.isOwner && (
      <Button onClick={() => {
        setVideoSource(videoSource === 'screen' ? 'camera' : 'screen')
      }} ><Sharing color={videoSource ==='camera' ? 'white': '#088ef4' }/></Button>
      )}

      <Button onClick={() => setVideo(!video)}><CameraOn color={video?"#088ef4":"red"}/></Button>
      {/* <Button variant="outlined" style={{ color: "white" }}>WhiteBoard</Button> */}
      
      {/* <Button onClick={ changeStudentsLayout }> */}
      <Button onClick={ () => { toast("🚀 This feature is coming soon.")} }>
        <Hamburger color={layout == STUDENTS_LAYOUT.GRID? "white" : "#088ef4"} /></Button>
      
      {/* <Button onClick={changeStudentsGridLayout} style={{color:"white"}}> */}
      <Button onClick={ () => { toast("🚀 This feature is coming soon.")} } style={{color:"white"}}>
        {/* {displaySidebar ? <ArrowForwardIcon/>: <ArrowBackIcon/>} */}
        <FullScreenSvg color={"white"}/>
        {/* {displaySidebar ? <Fullscreen sx={{ fontSize: 30 }}/> : <FullscreenExit sx={{ fontSize: 30 }}/>} */}
      </Button>
      <Button 
        variant="contained" 
        style={{
          background: "#F15A22",
          color: "white",
          borderRadius: "21px",
          height: "43px",
          border: "none",
          inlineSize: "89px",
          cursor: "pointer"
        }}
        onClick={doLeave}
      >
        Exit
      </Button>
    </Stack>
  }
/>
}

export default ActionBar;