import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useParams } from 'react-router-dom';

import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions
} from 'mic-check';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import Conversation from "./Conversation";

export default function ConversationJoin() {
  const navigate = useNavigate();
  const { conversationId } = useParams();

  const [conversation, serConversations] = useState(null);
  const [username, setUsername] = useState('');

  const [isJoined, setIsJoined] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const askForAccess = async (username) => {
    await requestMediaPermissions()
	.then(() => {
		// can successfully access camera and microphone streams
		// DO SOMETHING HERE
    setUsername(username);
    setIsJoined(true)
	})
	.catch((err: MediaPermissionsError) => {
		const { type, name, message } = err;
		if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
			// browser does not have permission to access camera or microphone
      toast("Camera/Microphone not available.");
      
		} else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
			// user didn't allow app to access camera or microphone
      toast('Please allow camera/mic permission & hit reload.');
      
		} else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
			// camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
			// (mostly Windows specific problem)
      toast('Camera/Microphone is being used by another app.');
      
		} else {
			// not all error types are handled by this library
      toast("Camera/Microphone not available.");      
		}
	});
  }

  useEffect(() => {
    // console.log('load');
    let userParam = searchParams.get('user');
    console.log(userParam);
    if(userParam) {
      localStorage.setItem('teacherUserName', userParam);
    }

    const uname = localStorage.getItem("teacherUserName");
    if (uname && uname.length && userParam) {
      
      // setUsername(uname);
      // setIsJoined(true);
      askForAccess(uname);
    }
    
  }, []);

  useEffect(() => {
    if (conversationId && conversationId.length) {
      axios.get(process.env.REACT_APP_API_BASE_URL + "/conversation/" + conversationId)
        .then(res => {
          serConversations(res.data.data)
        })
        .catch(err => {
          console.error("api error on conversation join");
          navigate('/');
        })
    }
  }, [conversationId])

  const handleClick = async (event) => {
    event.preventDefault();
    if (!username || username.length <= 0 || /^\s/.test(username)) {
      return toast('Please add a valid username to enter into the class')
    }
    localStorage.setItem('username', username);
    await askForAccess(username.trim());
    // setUsername(username);
    // setIsJoined(true)
  };

  return (conversation?._id && username && isJoined) ? <Conversation 
      conversationId={conversation._id} 
      userId={username}
      onExit={() => navigate('/')}
    /> : (
    <Box sx={{ 
      flexGrow: 1, 
      overflow: 'auto', 
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <h2 style={{ marginBottom: 0}}>Welcome to</h2>
      <h1>Dominion Prep</h1>

      <Box
        component="form"
        sx={{
          width: 300,
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleClick}
      >
        <TextField
          fullWidth
          required
          label="Enter User Name"
          name="username"
          autoFocus={true}
          onChange={(event) => setUsername(event.target.value)}
          value={username}
        />
        <Button
          fullWidth
          variant="contained"
          onClick={handleClick}
          sx={{ marginTop: '1rem'}}
        >
          Join Class
        </Button>
      </Box>
    </Box>
  );
}