import React from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { deepOrange } from '@mui/material/colors';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { Box } from "@mui/material";

import NavBar from './Components/NavBar';
import ConversationListPage from "./Pages/ConversationListPage";
import ConversationPage from "./Pages/ConversationPage";
import Whiteboard from "./Whiteboard";
import Home from "./Pages/Home";
import ConversationCreate from "./Pages/ConversationCreate";
import ConversationJoin from "./Pages/ConversationJoin";
import { ToastContainer } from 'react-toastify';

import "./style.css";

const theme = createTheme({
  palette: {
    primary: {
      main: deepOrange[500],
    },
    secondary: {
      main: '#231F20',
    },
  },
  typography: {
    title: {
      display: 'block',
      fontSize: '1.1rem',
      lineHeight: 1,
    },
    poster: {
      color: '#FCAF17',
    },
    subtitle: {
      display: 'block',
      fontSize: '.9rem',
      lineHeight: 1,
      color: 'lightgrey'
    }
  },
});

function App() {
  return (
    <>
        <ToastContainer closeButton={false} />

        <ThemeProvider theme={theme}>
            <Box
              sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <NavBar/>
              <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                <BrowserRouter>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/conversation/create" element={<ConversationCreate />} />
                    <Route path="/conversation/:conversationId" element={<ConversationJoin />} />
                    {/* <Route path="/conversation" element={<ConversationListPage />} exact/> */}
                    <Route path="/whiteboard" element={<Whiteboard />} />
                    <Route
                      path="*"
                      element={<Navigate to="/" />}
                    />
                  </Routes>
                </BrowserRouter>
              </Box>
              {/* <NavBar/> */}
            </Box>
        </ThemeProvider>
    
    </>

  );
}

export default App;