import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useParams } from 'react-router-dom';

export default function ConversationCreate() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');

  const CHARACTER_LIMIT = 10;
  const [getString, setString]= useState({
    value: "",
    length: 0
  })

  // useEffect(() => {
  //   if (token) {
  //     if (token !== process.env.REACT_APP_DOMINION_API_KEY) navigate('/');
  //     const uname = localStorage.getItem("username");
  //     if (uname && uname.length) setUsername(uname);
  //   }
  // }, [token]);

  async function createConversation(user) {
    console.log(process.env.REACT_APP_HTTP_BASIC_AUTH_USER);
    console.log(process.env.REACT_APP_HTTP_BASIC_AUTH_PASSWORD);

    try {
      const payload = await axios.post(process.env.REACT_APP_API_BASE_URL + "/conversation", {
        teacher:[
            {
                userName: user,
                userId: user
            }
        ],
        students: []
      }, 
      {
        auth: {
          username: process.env.REACT_APP_HTTP_BASIC_AUTH_USER,
          password: process.env.REACT_APP_HTTP_BASIC_AUTH_PASSWORD
        }
      });
      return payload?.data?.data;
    } catch (err) {
      alert('Error Creating a Class');
    }
  }

  const handleClick = async (event) => {
    if (!username || username.length <= 0) {
      return alert('Please add username to enter into the class')
    }
    localStorage.setItem('username', username);
    const conversation = await createConversation(username);
    navigate(`/conversation/${conversation._id}`)
  };

  const onHandleChangeInput = (field, value) => {
    setString({value: value, length: value.length})

  }

  return (
    <Box sx={{ 
      flexGrow: 1, 
      overflow: 'auto', 
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <h2 style={{ marginBottom: 0}}>Welcome to</h2>
      <h1>Dominion Prep</h1>

      <Box
        component="form"
        sx={{
          width: 300,
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          fullWidth
          required
          label="Enter User Name"
          name="username"
          onChange={(event) => setUsername(event.target.value)}
          value={username}
          inputProps={{
            maxlength: CHARACTER_LIMIT
          }}
          helperText="Please enter 10 carector username"
        />
        <Button
          fullWidth
          variant="contained"
          onClick={handleClick}
          sx={{ marginTop: '1rem'}}
        >
          + Create New Class
        </Button>
      </Box>
    </Box>
  );
}