import { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { OTSubscriber } from 'opentok-react';
import Unmute from './Unmute';
import IconButton from '@mui/material/IconButton';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Speaker from './Speaker';

export default function Student({
  activeStudent,
  stream,
  makeBigger,
  sessionHelper
}) {
  const [audio, setAudio] = useState(true);
  return (
    <div style={{ width: '100%', height: '100%', }}>
      {activeStudent === stream.id && <button className='backBtn' onClick={()=>makeBigger(stream.id)} ><ArrowBackIcon /></button>}
      <OTSubscriber 
        style={{height: '83%'}}
        className="opshadow"
        key={stream.id}
        session={sessionHelper.session}
        stream={stream}
        properties={{
          subscribeToAudio: audio,
          showControls: false
        }}
        retry={true}
        maxRetryAttempts={3}
        retryAttemptTimeout={2000}
      />
      <ImageListItemBar
        // sx={{ background: "#000", color: "#fff", opacity:"0.5", }}
        title={<div style={{paddingLeft:'8px', alignItems:'left'}} title={JSON.parse(stream.connection.data).userName}>{
          JSON.parse(stream.connection.data).userName === JSON.parse(stream.connection.data).userName.slice(0,12) ? 
          JSON.parse(stream.connection.data).userName.slice(0,12) : 
          JSON.parse(stream.connection.data).userName.slice(0,12) + '...'
          }
          </div>}
        // subtitle={item.author}
        position="below"
        variant="standard"
        actionIcon={
          <IconButton
            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
            aria-label={`info about student`}
            onClick={() => setAudio(!audio)} 
          >
            {/* <Unmute color={audio?"#088ef4":"red"}/> */}
            <Speaker color={audio?"#088ef4":"red"}/>
          </IconButton>
        }
      />
    </div>
  );
}
