import { OTPublisher, OTSubscriber } from 'opentok-react';
import { useState } from 'react'
import styles from './teacher.module.css'


const opentokProp = {
  showControls: false
};

export default function Teacher({ 
  sessionHelper, 
  streams, 
  teacher, 
  currentParticipant,
  audio,
  video,
  videoSource,
  publisherEventHandlers
}) {
  return (
    <div className={styles.TeacherPanel} style={{background:'azure'}}>
      {currentParticipant?.isOwner ? (
        <OTPublisher 
          properties={{
            showControls: false,
            publishAudio: audio,
            publishVideo: video,
            videoSource: videoSource === 'screen' ? 'screen' : undefined
          }} 
          eventHandlers={publisherEventHandlers}
          session={sessionHelper.session} 
          style={{width: '100%', height:'100%'}} 
        />
      ) : (streams.filter(obj => {
        return JSON.parse(obj.connection?.data).userId === teacher?.userId
      }).map(stream => (
        <OTSubscriber
          properties={opentokProp}
          key={stream.id}
          session={sessionHelper.session}
          stream={stream}
        />
      )))}
    </div>
  );
}
